import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  type IBillFindManyForCycleQuery,
  useBillFindManyForCycleQuery,
} from '@invisible/concorde/gql-client'
import { format } from '@invisible/ui/helpers'
import { adminTheme } from '@invisible/ui/mui-theme-v2'
import type { PrismaAll, TBillEventSource } from '@invisible/ultron/prisma'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import BusinessIcon from '@mui/icons-material/Business'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import type { ToolbarProps } from '@mui/material/Toolbar'
import {
  DataGridPro,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  type GridColDef,
  GridComparatorFn,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterModel,
  GridInitialState,
  GridPagination,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import { compact, flatten, isEmpty, sumBy } from 'lodash/fp'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useContext,useEffect, useMemo, useState } from 'react'

import { MANTICORE_URL } from '../config/env'
import { BillDetails } from './BillDetails'
import { Chips } from './common/Chips'
import { statusSortOrder } from './constants'
import { CycleSelectionMenu } from './CycleSelectionMenu'
import {
  getAgentStatusChipStyle,
  getAgentStatusOptionLabel,
  getBillPaymentStatusColor,
  getBillPaymentStatusLabel,
  getDataSourceOptionLabel,
  getSortDirection,
} from './helpers'
import {
  CompanyFilter,
  ManagerFilter,
  ProcessFilter,
  TeamFilter,
} from './table-components/custom-filters'
import { CustomHeader } from './table-components/CustomHeader'
import { CustomToolbar, type ISelectedBillRow } from './table-components/CustomToolbar'

type Bill = IBillFindManyForCycleQuery['findManyBillsForCycle']['edges'][number]['node']
export interface BillRowData {
  id: string
  userId: string
  userImg: Bill['user']['image']
  userEmail: Bill['user']['email']
  userName: Bill['user']['name']
  offboardedAt?: Date | null
  teams: Bill['user']['teams']
  processes: Bill['processNames']
  companies: Bill['companyNames']
  manager?: string | null
  managerId?: string | null
  managerImg?: string | null
  managerEmail?: string | null
  total: Bill['totalAmount']
  change: Bill['cycleChangeAmount']
  uncheckedAmount: Bill['uncheckedAmount']
  readyToSendAmount: Bill['readyToSendAmount']
  sentToFinanceAmount: Bill['sentToFinanceAmount']
  status: Bill['paymentStatus']
}

interface IProps {
  cycleIndex: number
  drafterEmail?: string
  refetch: boolean
  clearRowSelection: boolean
  setSelectedBillRows: Dispatch<SetStateAction<ISelectedBillRow[]>>
  setIsDrafting: Dispatch<SetStateAction<boolean>>
  setRefetch: Dispatch<SetStateAction<boolean>>
}

type Error = {
  meta: {
    response: {
      errors: Array<SingleError>
    }
  }
}

type SingleError = {
  locations: Array<{line: number, column: number}>
  message: string
  path: Array<string>
}

const billStatusComparator: GridComparatorFn<string> = (value1, value2) =>
  statusSortOrder[value1] - statusSortOrder[value2]

const filterColumns = ({ field, columns, currentFilters }: FilterColumnsArgs) => {
  // remove already filtered fields from list of columns
  const filteredFields = currentFilters
    ?.filter((item) => !['status', 'agentStatus'].includes(item.field))
    ?.map((item) => item.field)
  return columns
    .filter(
      (colDef) =>
        colDef.filterable && (colDef.field === field || !filteredFields.includes(colDef.field))
    )
    .map((column) => column.field)
}

const getColumnForNewFilter = ({ currentFilters, columns }: GetColumnForNewFilterArgs) => {
  const filteredFields = currentFilters?.map(({ field }) => field)
  const columnForNewFilter = columns
    .filter((colDef) => colDef.filterable && !filteredFields.includes(colDef.field))
    .find((colDef) => colDef.filterOperators?.length)
  return columnForNewFilter?.field ?? null
}

const ManticoreBillsTable = ({
  cycleIndex,
  drafterEmail,
  refetch,
  clearRowSelection,
  setSelectedBillRows,
  setIsDrafting,
  setRefetch,
}: IProps) => {
  const router = useRouter()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [selectedCycleIndex, setSelectedCycleIndex] = useState(cycleIndex ?? 1)
  const [, setCurrentPage] = useState<number>(1)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  })
  const [rowCountState, setRowCountState] = useState<number>(0)
  const [filterModel, setFilterModel] = useState<GridFilterModel>()
  const [sortModel, setSortModel] = useState<GridSortModel>()
  const [initialState, setInitialState] = useState<GridInitialState | undefined>({
    pinnedColumns: {
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'total', 'userName'],
    },
    columns: {
      columnVisibilityModel: {
        dataSource: false,
      },
    },
  })
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>()
  const [billRowData, setBillRowData] = useState<BillRowData>()
  const [showBillDetailsModal, setShowBillDetailsModal] = useState(false)
  const [endCursor, setEndCursor] = useState<string | null>()

  const { showSnackbar } = useContext(SnackbarContext)

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    router.query = {
      ...router.query,
      filters: JSON.stringify(filterModel.items),
    }
    router.replace(
      {
        query: {
          ...router.query,
        },
      },
      undefined,
      { shallow: true }
    )
    setFilterModel(filterModel)
  }

  const onSortModelChange = (sortModel: GridSortModel) => {
    router.query = {
      ...router.query,
      sort: JSON.stringify(sortModel),
    }
    router.replace(
      {
        query: {
          ...router.query,
        },
      },
      undefined,
      { shallow: true }
    )
    setSortModel(sortModel)
  }

  const applySorting = () => {
    if (!sortModel || !sortModel?.length) return null

    const sortItems = sortModel.filter(({ field }) => field === 'userName')
    if (!sortItems) return null

    const userNameSortItem = sortItems.find(({ field }) => field === 'userName')

    if (!userNameSortItem) return null

    return {
      ...(userNameSortItem
        ? {
            user: {
              name: getSortDirection(userNameSortItem.sort),
            },
          }
        : {}),
    }
  }

  const managerFilterValues = flatten(
    filterModel?.items
      .filter(({ field }) => field === 'manager')
      ?.map(({ value }) => value as { id: string; name?: string | null })
  )
  const teamFilterValues = flatten(
    filterModel?.items
      .filter(({ field }) => field === 'teams')
      ?.map(({ value }) => value as { id: string; name: string })
  )
  const processFilterValues = flatten(
    filterModel?.items
      .filter(({ field }) => field === 'processes')
      ?.map(({ value }) => value as { id: string; name: string })
  )
  const companyFilterValues = flatten(
    filterModel?.items
      .filter(({ field }) => field === 'companies')
      ?.map(({ value }) => value as { id: string; name: string })
  )
  const sourceFilterValues = filterModel?.items
    .filter(({ field }) => field === 'dataSource')
    ?.map(({ value }) => value)

  const {
    data,
    error,
    isLoading: isFetchingBills,
    isRefetching,
    refetch: refetchBills,
  } = useBillFindManyForCycleQuery(
    {
      startDate,
      endDate,
      first: 100,
      after: endCursor,
      filters: {
        userName: filterModel?.quickFilterValues?.length
          ? filterModel?.quickFilterValues?.join(' ')
          : filterModel?.items.find(({ field }) => field === 'userName')?.value ?? undefined,
        managerIds: !isEmpty(compact(managerFilterValues))
          ? managerFilterValues?.filter((value) => !isEmpty(value.id))?.map((value) => value.id)
          : undefined,
        teamIds: !isEmpty(compact(teamFilterValues))
          ? teamFilterValues?.filter((value) => !isEmpty(value.id))?.map((value) => value.id)
          : undefined,
        paymentStatus: filterModel?.items
          .filter(({ field }) => field === 'status')
          ?.map(({ value }) => (value === 'null' || value === undefined ? null : value)),
        processIds: !isEmpty(compact(processFilterValues))
          ? processFilterValues?.filter((value) => !isEmpty(value.id))?.map((value) => value.id)
          : undefined,
        companyIds: !isEmpty(compact(companyFilterValues))
          ? companyFilterValues?.filter((value) => !isEmpty(value.id))?.map((value) => value.id)
          : undefined,
        sources: !isEmpty(compact(sourceFilterValues))
          ? sourceFilterValues?.map((value) => value)
          : undefined,
        offboarded: (() => {
          const value = filterModel?.items.find(({ field }) => field === 'agentStatus')?.value
          return typeof value === 'boolean' ? value : undefined
        })(),
      },
      order: applySorting(),
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  const bills = data?.findManyBillsForCycle.edges?.map(({ node }) => node)

  const pageEndCursor = data?.findManyBillsForCycle.pageInfo?.endCursor

  useEffect(() => {
    if (error && (error as Error).meta.response.errors.length > 0) {
      const errorMessages: string = (error as Error).meta.response.errors.map((singleError: SingleError) => singleError.message).join(', ')
      showSnackbar({
        message: errorMessages,
        variant: 'error',
        autoHide: false
      })
    }
  }, [error, showSnackbar])

  const rows = useMemo(
    () =>
      bills?.map((bill) => ({
        id: fromGlobalId(bill?.id),
        userId: fromGlobalId(bill?.user?.id),
        userEmail: bill?.user?.email,
        userName: bill?.user?.name,
        userImg: bill?.user?.image,
        offboardedAt: bill?.user?.offboardedAt,
        teams: bill?.user?.teams,
        processes: bill?.processNames,
        companies: bill?.companyNames,
        manager: bill?.user?.manager?.name,
        managerId: bill?.user?.manager?.id ? fromGlobalId(bill?.user?.manager?.id) : undefined,
        managerImg: bill?.user?.manager?.image,
        managerEmail: bill?.user?.manager?.email,
        total: bill?.totalAmount,
        change: bill?.cycleChangeAmount,
        uncheckedAmount: bill?.uncheckedAmount,
        readyToSendAmount: bill?.readyToSendAmount,
        sentToFinanceAmount: bill?.sentToFinanceAmount,
        status: bill?.paymentStatus,
      })) ?? [],
    [bills]
  ) as BillRowData[]

  const columns = useMemo<GridColDef<BillRowData>[]>(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hideable: false
      },
      {
        field: 'total',
        headerName: 'Bill Total',
        width: 170,
        display: 'flex',
        valueFormatter: (value) => format.formatMillicents(value ?? 0),
        renderHeader: (params) => {
          const selectedBillsTotal = compact(
            rowSelectionModel?.map((id) => {
              const bill = bills?.find((bill) => fromGlobalId(bill.id) === id)
              if (!bill) return
              return {
                total: bill.totalAmount,
              }
            })
          )

          return (
            <CustomHeader title={params.colDef.headerName}>
              <div className='text-xs font-light text-gray-400'>
                {!isEmpty(selectedBillsTotal)
                  ? format.formatMillicents(sumBy('total', selectedBillsTotal))
                  : format.formatMillicents(sumBy('totalAmount', bills))}
              </div>
            </CustomHeader>
          )
        },
        renderCell: (params) => (
          <div className='grid w-full grid-cols-2 items-center gap-1'>
            <div>{format.formatMillicents(params.row.total ?? 0)}</div>
            <Button
              size='small'
              sx={{ color: '#535157' }}
              onClick={(_) => {
                setBillRowData(params.row)
                setShowBillDetailsModal(true)
              }}>
              Details
            </Button>
          </div>
        ),
        filterable: false,
      },
      {
        field: 'userName',
        type: 'string',
        headerName: 'Agent',
        width: 200,
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        renderCell: (params) => (
          <Stack
            component='a'
            href={`${MANTICORE_URL}/profile/${params.row.userId}`}
            target='_blank'
            rel='noreferrer'
            direction='row'
            spacing={1}
            sx={{
              cursor: 'pointer',
              color: 'inherit',
              textDecoration: 'none',
              textDecorationLine: 'none',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            alignItems='center'>
            <div className='flex h-full'>
              <Avatar
                alt={params.row.userName ?? ''}
                src={params.row.userImg ?? ''}
                sx={{ width: 24, height: 24 }}
              />
            </div>
            <div className='space-y-1'>
              <div>{params.row.userName}</div>
              <div className='text-gray-500'>{`${params.row.userEmail?.split('@')[0]}@`}</div>
            </div>
          </Stack>
        ),
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        ),
      },
      {
        field: 'agentStatus',
        type: 'singleSelect',
        headerName: 'Agent Status',
        width: 150,
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        renderCell: (params) => {
          const agentStatusValue = params.row.offboardedAt ? 'Inactive' : 'Active'

          return (
            <div>
              <Chip
                size='small'
                sx={{
                  height: 'auto',
                  p: '2px',
                  borderRadius: '16px',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                  borderColor: 'transparent',
                  ...getAgentStatusChipStyle(),
                }}
                label={agentStatusValue}
              />
            </div>
          )
        },
        valueGetter: (_, row) => (row.offboardedAt ? 'Inactive' : 'Active'),
        valueFormatter: (value) => value,
        valueOptions: ['null', true, false].map((value) => ({
          value,
          label: getAgentStatusOptionLabel(value),
        })),
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'is'
        ),
      },
      {
        field: 'status',
        type: 'singleSelect',
        headerName: 'Bill Status',
        width: 150,
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        renderCell: (params) => (
          <div>
            {
              <Chip
                size='small'
                sx={{
                  height: 'auto',
                  p: '2px',
                  borderRadius: '16px',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                  color: 'white',
                  backgroundColor: getBillPaymentStatusColor(params.row.status),
                  borderColor: 'transparent',
                }}
                label={getBillPaymentStatusLabel(params.row.status)}
              />
            }
          </div>
        ),
        valueGetter: (_, row) => getBillPaymentStatusLabel(row.status),
        valueFormatter: (value) => value,
        valueOptions: (
          ['approved', 'drafted', 'partially_drafted', 'null'] as Array<
            PrismaAll.BillPaymentStatus | 'null'
          >
        ).map((status) => ({
          value: status,
          label: getBillPaymentStatusLabel(status),
        })),
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'is'
        ),
        sortComparator: billStatusComparator,
      },
      {
        field: 'sentToFinanceAmount',
        headerName: 'Sent to Finance',
        width: 150,
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        renderCell: (params) => {
          const billTotal = params.row.total
          const draftedBillEventsAmount = params.row.sentToFinanceAmount
          const draftedPercentage = (draftedBillEventsAmount / billTotal) * 100

          return (
            <div>
              {format.formatMillicents(draftedBillEventsAmount)}(
              {(!isNaN(draftedPercentage) ? draftedPercentage : 0).toFixed(2)}%)
            </div>
          )
        },
        valueGetter: (_, row) => row.sentToFinanceAmount,
        valueFormatter: (value) => format.formatMillicents(value),
        filterable: false,
      },
      {
        field: 'readyToSend',
        headerName: 'Ready to Send',
        width: 150,
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        renderCell: (params) => {
          const billTotal = params.row.total ?? 0
          const preApprovedBillEventsAmount = params.row.readyToSendAmount
          const preApprovedPercentage = (preApprovedBillEventsAmount / billTotal) * 100

          return (
            <div>
              {format.formatMillicents(preApprovedBillEventsAmount)}(
              {(!isNaN(preApprovedPercentage) ? preApprovedPercentage : 0).toFixed(2)}%)
            </div>
          )
        },
        valueGetter: (_, row) => row.readyToSendAmount,
        valueFormatter: (value) => format.formatMillicents(value),
        filterable: false,
      },
      {
        field: 'unchecked',
        headerName: 'Unchecked',
        width: 150,
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        renderCell: (params) => {
          const billTotal = params.row.total ?? 0
          const unapprovedBillEventsAmount = params.row.uncheckedAmount
          const unapprovedPercentage = (unapprovedBillEventsAmount / billTotal) * 100

          return (
            <div>
              {format.formatMillicents(unapprovedBillEventsAmount)}(
              {(!isNaN(unapprovedPercentage) ? unapprovedPercentage : 0).toFixed(2)}%)
            </div>
          )
        },
        valueGetter: (_, row) => row.uncheckedAmount,
        valueFormatter: (value) => format.formatMillicents(value),
        filterable: false,
      },
      {
        field: 'change',
        headerName: 'Change',
        width: 150,
        display: 'flex',
        renderHeader: (params) => {
          const selectedBillsChange = compact(
            rowSelectionModel?.map((id) => {
              const bill = bills?.find((bill) => fromGlobalId(bill.id) === id)
              if (!bill) return
              return {
                change: bill.cycleChangeAmount,
              }
            })
          )

          return (
            <CustomHeader title={params.colDef.headerName}>
              <div className='text-error-main text-xs font-light'>
                {!isEmpty(selectedBillsChange)
                  ? format.formatMillicents(sumBy('change', selectedBillsChange))
                  : format.formatMillicents(sumBy('cycleChangeAmount', bills))}
              </div>
            </CustomHeader>
          )
        },
        renderCell: (params) => <div>{format.formatMillicents(params.row.change)}</div>,
        valueFormatter: (value) => format.formatMillicents(value),
        filterable: false,
      },
      {
        field: 'processes',
        type: 'singleSelect',
        headerName: 'Processes',
        width: 350,
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        renderCell: (params) => (
          <div className='overflow-x-auto'>
            <Chips
              display={1}
              chips={
                params.row.processes
                  ?.sort((a, b) => a.localeCompare(b))
                  ?.map((processName) => ({
                    label: processName,
                    color: 'primary',
                    key: processName,
                    icon: <AccountTreeIcon />,
                    tooltip: processName,
                  })) ?? []
              }
            />
          </div>
        ),
        valueGetter: (_, row) =>
          row.processes
            ?.sort((a, b) => a.localeCompare(b))
            ?.map((processName) => processName)
            ?.join(', '),
        valueFormatter: (value) => value,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value === 'is')
          .map((operator) => ({
            ...operator,
            InputComponent: (props: GridFilterInputValueProps) => (
              <ProcessFilter {...props} enabled={!isEmpty(bills)} />
            ),
          })),
      },
      {
        field: 'companies',
        type: 'singleSelect',
        headerName: 'Clients',
        width: 200,
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        renderCell: (params) => (
          <div className='overflow-x-auto'>
            <Chips
              display={1}
              chips={
                compact(params.row.companies)
                  ?.sort((a, b) => a.localeCompare(b))
                  ?.map((companyName) => ({
                    label: companyName,
                    color: 'primary',
                    key: companyName,
                    icon: <BusinessIcon />,
                    tooltip: companyName,
                  })) ?? []
              }
            />
          </div>
        ),
        valueGetter: (_, row) =>
          compact(row.companies)
            ?.sort((a, b) => a.localeCompare(b))
            ?.map((companyName) => companyName)
            ?.join(', '),
        valueFormatter: (value) => value,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value === 'is')
          .map((operator) => ({
            ...operator,
            InputComponent: (props: GridFilterInputValueProps) => (
              <CompanyFilter {...props} enabled={!isEmpty(bills)} />
            ),
          })),
      },
      {
        field: 'manager',
        type: 'singleSelect',
        headerName: 'Manager',
        width: 200,
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        renderCell: (params) =>
          params.row.managerId ? (
            <Stack
              component='a'
              href={`${MANTICORE_URL}/profile/${params.row.managerId}`}
              target='_blank'
              rel='noreferrer'
              direction='row'
              spacing={1}
              sx={{
                cursor: 'pointer',
                color: 'inherit',
                textDecoration: 'none',
                textDecorationLine: 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              alignItems='center'>
              <div className='flex h-full'>
                <Avatar
                  alt={params.row.manager ?? ''}
                  src={params.row.managerImg ?? ''}
                  sx={{ width: 24, height: 24 }}
                />
              </div>
              <div className='space-y-1'>
                <div>{params.row.manager}</div>
                <div className='text-gray-500'>{`${params.row.managerEmail?.split('@')[0]}@`}</div>
              </div>
            </Stack>
          ) : (
            <div></div>
          ),
        valueGetter: (_, row) => row.manager,
        valueFormatter: (value) => value,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value === 'is')
          .map((operator) => ({
            ...operator,
            InputComponent: (props: GridFilterInputValueProps) => (
              <ManagerFilter {...props} enabled={!isEmpty(bills)} />
            ),
          })),
      },
      {
        field: 'teams',
        type: 'singleSelect',
        headerName: 'Teams',
        width: 350,
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        renderCell: (params) => (
          <div className='overflow-x-auto'>
            <Chips
              display={1}
              chips={
                params.row.teams
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  ?.map(({ name: teamName }) => ({
                    label: teamName,
                    color: 'primary',
                    key: teamName,
                    icon: <GroupOutlinedIcon />,
                    tooltip: teamName,
                  })) ?? []
              }
            />
          </div>
        ),
        valueGetter: (_, row) =>
          row.teams
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map(({ name: teamName }) => teamName)
            ?.join(', '),
        valueFormatter: (value) => value,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value === 'is')
          .map((operator) => ({
            ...operator,
            InputComponent: (props: GridFilterInputValueProps) => (
              <TeamFilter {...props} enabled={!isEmpty(bills)} />
            ),
          })),
      },
      {
        field: 'dataSource',
        type: 'singleSelect',
        headerName: 'Data Source',
        display: 'flex',
        renderHeader: (params) => <CustomHeader title={params.colDef.headerName} />,
        valueOptions: (['platform', 'csv_upload'] as TBillEventSource[]).map((source) => ({
          value: source,
          label: getDataSourceOptionLabel(source),
        })),
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'is'
        ),
      },
    ],
    [rowSelectionModel, bills]
  )

  useEffect(() => {
    const { query } = router
    const filterItems = query?.filters
      ? (JSON.parse(query.filters as string) as GridFilterItem[])
      : []
    const sortModel = query?.sort ? (JSON.parse(query.sort as string) as GridSortModel) : []
    if (!isEmpty(filterItems) && isEmpty(initialState?.filter?.filterModel?.items)) {
      setInitialState((prev) => ({
        ...prev,
        filter: { filterModel: { items: filterItems } },
      }))
      setFilterModel((prev) => ({ ...prev, items: filterItems }))
    }
    if (!isEmpty(sortModel) && isEmpty(initialState?.sorting?.sortModel)) {
      setInitialState((prev) => ({
        ...prev,
        sorting: {
          sortModel,
        },
      }))
      setSortModel(sortModel)
    }
  }, [
    initialState?.filter?.filterModel?.items,
    initialState?.sorting?.sortModel,
    router,
    router.isReady,
  ])

  useEffect(
    () => setRowCountState((prev) => data?.findManyBillsForCycle.totalCount ?? prev),
    [data?.findManyBillsForCycle.totalCount]
  )

  useEffect(() => {
    if (refetch) refetchBills()
    setRefetch(false)
  }, [refetch, refetchBills, setRefetch])

  useEffect(() => {
    if (clearRowSelection) setRowSelectionModel([])
  }, [clearRowSelection])

  return (
    <div className='w-full'>
      <div className='mb-2'>
        <CycleSelectionMenu
          updateRouterParam
          selectedIndex={selectedCycleIndex}
          onCycleChange={(start, end, index) => {
            setStartDate(start)
            setEndDate(end)
            setSelectedCycleIndex(index)
          }}
        />
      </div>
      <div className='h-full max-h-[calc(100vh-150px)] w-full'>
        <DataGridPro
          disableDensitySelector
          pagination
          paginationMode='server'
          filterMode='server'
          sx={{ overflow: 'auto' }}
          loading={isFetchingBills || isRefetching}
          rows={rows}
          rowCount={rowCountState}
          columns={columns}
          initialState={initialState}
          slots={{
            ...adminTheme.components?.MuiDataGrid?.defaultProps?.slots,
            toolbar: CustomToolbar as (p: ToolbarProps) => JSX.Element,
            pagination: GridPagination,
          }}
          slotProps={{
            toolbar: {
              selectedBillRows: compact(
                rowSelectionModel?.map((id) => {
                  const bill = bills?.find((bill) => fromGlobalId(bill.id) === id)
                  if (!bill) return
                  return {
                    id: id as string,
                    userEmail: bill?.user?.email,
                    status: bill.paymentStatus,
                    manticoreBillId: fromGlobalId(bill.id),
                    drafterEmail: drafterEmail ?? '',
                    name: bill?.user?.name ?? '',
                  }
                })
              ),
              setRowSelectionModel,
              setIsDrafting,
              setRefetch,
            },
            filterPanel: {
              columnsSort: 'asc',
              getColumnForNewFilter,
              filterFormProps: {
                filterColumns,
                logicOperatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                valueInputProps: {
                  sx: {
                    width: '400px',
                    mt: 'auto',
                  },
                },
              },
              sx: {
                '& .MuiDataGrid-filterForm': { p: 2 },
                '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
              },
            },
          }}
          pageSizeOptions={[100]}
          paginationModel={paginationModel}
          onPaginationModelChange={(model) => {
            setEndCursor(pageEndCursor)
            setCurrentPage(model.page + 1)
            setPaginationModel(model)
          }}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
            setSelectedBillRows(
              compact(
                newRowSelectionModel.map((id) => {
                  const bill = bills?.find((bill) => fromGlobalId(bill.id) === id)
                  if (!bill) return
                  return {
                    id: id as string,
                    userEmail: bill?.user?.email,
                    status: bill.paymentStatus,
                    manticoreBillId: fromGlobalId(bill.id),
                    drafterEmail: drafterEmail ?? '',
                    name: bill?.user?.name ?? '',
                  }
                })
              )
            )
          }}
        />
      </div>
      {billRowData && showBillDetailsModal ? (
        <BillDetails
          open={showBillDetailsModal}
          billRowData={billRowData}
          selectedCycleIndex={selectedCycleIndex}
          drafterEmail={drafterEmail ?? ''}
          onClose={() => {
            setBillRowData(undefined)
            setShowBillDetailsModal(false)
          }}
        />
      ) : null}
    </div>
  )
}

export default ManticoreBillsTable
